import { LayoutProps } from "../types/pageWithLayouts";

const DefaultLayout: LayoutProps = ({ children }) => {
  return (
    <>
      <div style={{ backgroundColor: "#F7F7F7" }}>{children}</div>
    </>
  );
};
export default DefaultLayout;
