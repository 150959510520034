
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Typography } from "antd";
import { Spin } from "antd";
import { useEffect } from "react";
import { useRouter } from "next/router";
import DefaultLayout from "../layouts/default.layout";
import useUserStore from "../zustand/useUserStore";
import useTranslation from "next-translate/useTranslation";

const { Title, Text } = Typography;

const CheckAuth = () => {
  const { t, lang } = useTranslation("common");
  const router = useRouter();
  const token = useUserStore((state: any) => state.token);

  useEffect(() => {
    // Navigate to login if enter root.
    router.push("/login");
  }, []);

  return (
    <>
      <div
        style={{
          width: "25%",
          textAlign: "center",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed",
        }}
      >
        <Spin tip={`${t("checking-auth")}...`}></Spin>
      </div>
    </>
  );
};
CheckAuth.layout = DefaultLayout;
export default CheckAuth;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  